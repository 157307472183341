import React, { useContext, useEffect, useState } from "react";

import styled from "@emotion/styled";
import type { NextPage } from "next";
import { useRouter } from "next/router";

import { Button, PageTitle } from "@/components";
import { Base, FeedbackModal } from "@/containers";
import { FooterHeightContext } from "@/containers/Layout/Layout";
import content from "@/content";
import { getHomePageData } from "@/request/homepage";
import routing from "@/routing";
import { theme } from "@/styles";

const testIdBase = content.pages.home.testId;

const Text = styled.div`
  flex-basis: 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1025px) {
    order: 1;
    width: 100%;
    padding: calc(0.1vw + 1px) !important;
    margin-top: 1rem !important;
    margin-bottom: 10px !important;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 10px;
`;

const TextContent = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1025px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1025px) {
    justify-content: flex-end;
  }
`;

interface IImage {
  footerHeight: number;
}

const Image = styled.img<IImage>`
  height: calc(100vh - ${(props) => props.footerHeight}px - 48px);
  object-fit: contain;

  @media (max-width: 1025px) {
    max-width: 100%;
    height: auto;
  }
`;

const TextContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  text-align: center;

  @media (max-width: 1025px) {
    flex: 1;
    justify-content: center;
  }
`;

const Home: NextPage = () => {
  const router = useRouter();

  const footerHeight = useContext(FooterHeightContext) || 0;

  const [homePageData, setHomePageData] = useState<THomePage | null>();

  const [isOpenFeedback, setIsOpenFeedback] = useState(false);

  const [isError, setError] = useState(false);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getHomePageData().then((data) => {
      setHomePageData(data);

      if (!data) {
        setError(true);
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const { openFeedback } = router.query;

    setIsOpenFeedback(openFeedback === "true");
  }, [router.query]);

  return (
    <Base error={isError} loading={isLoading}>
      <FeedbackModal bypassPreviousOpening isOpenFeedback={isOpenFeedback} testId={testIdBase} />
      <Container>
        <TextContainer>
          <Text>
            <TitleWrapper>
              <PageTitle
                fontColor={theme.palette.primary.main}
                testId={testIdBase}
                title={homePageData?.title || ""}
                variant="h4"
              />
            </TitleWrapper>
            <TextContent>{homePageData?.content}</TextContent>
            <Button
              fullWidth
              onClick={() => router.push(homePageData?.buttonRedirectPath || routing.home)}
              size="large"
              testId={testIdBase}
              variant="contained"
            >
              {homePageData?.buttonLabel}
            </Button>
          </Text>
        </TextContainer>
        <ImageContainer>
          <Image alt={"homepage"} footerHeight={footerHeight} loading="eager" src={homePageData?.image} />
        </ImageContainer>
      </Container>
    </Base>
  );
};

export default Home;
