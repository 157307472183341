import { readSingleton } from "@directus/sdk";

import directus from "@/request/client/directus";

const getHomePageData = async (): Promise<THomePage | null> => {
  try {
    const pageContent = await directus.request(
      readSingleton("homepage", {
        fields: ["button_label", "button_redirect_path", "content", "image", "title"],
      })
    );

    if (!pageContent) {
      return null;
    }

    return {
      buttonLabel: pageContent.button_label,
      buttonRedirectPath: pageContent.button_redirect_path,
      content: pageContent.content,
      image: `${process.env.NEXT_PUBLIC_BACKEND_ENDPOINT}/assets/${pageContent.image}`,
      title: pageContent.title,
    };
  } catch {
    return null;
  }
};

export { getHomePageData };
